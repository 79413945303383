<template>
  <div
    v-if="labelsStatus === 'success'"
    class="pa-5 overflow-y-auto d-flex flex-column"
  >
    <v-subheader
      class="pa-0 font-weight-bold mb-2"
      style="font-size: 18px; height: auto"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon
            class="ant-icon mr-2"
            @click="$router.push({ name: 'project-overview' })"
            v-on="on"
          >
            mdi-chevron-left
          </v-icon>
        </template>
        <span>Back to project overview</span>
      </v-tooltip>
      Labels
      <v-spacer />
      <transition mode="out-in" name="simple-fade">
        <v-tooltip v-if="!addLabelToggle" bottom>
          <template #activator="on">
            <v-icon
              class="ant-icon"
              dense
              @click="addLabelToggle = true"
              v-on="on"
            >
              mdi-plus
            </v-icon>
          </template>
          <span>New Label</span>
        </v-tooltip>
        <ant-input v-else label="Label name" style="margin-top: 0 !important">
          <template #input-field>
            <v-text-field
              ref="label-add-field"
              v-model="addLabelTitle"
              autofocus
              class="font-weight-regular"
              clearable
              dense
              filled
              hide-details
              label="Label"
              single-line
              @click:clear="
                addLabelTitle = null;
                addLabelToggle = false;
              "
              @keydown.enter="createLabel"
              @keydown.esc="
                addLabelTitle = null;
                addLabelToggle = false;
              "
            />
          </template>
        </ant-input>
      </transition>
    </v-subheader>
    <v-subheader class="pa-0 mb-5" style="height: auto"
      >Here you can manage project task labels. These labels are available to
      all users within this project
    </v-subheader>
    <div class="flex-1 overflow-y-auto">
      <div
        v-for="item in labels"
        :key="item.id"
        class="admin-label-item ant-glass-background radius-0 d-flex align-center px-5 py-2 mb-5"
      >
        <v-menu
          v-model="menus.color[item.id]"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-icon class="mr-15" v-bind="attrs" v-on="on"
              >mdi-format-color-fill
            </v-icon>
          </template>

          <v-card class="pa-5">
            <v-color-picker
              v-model="updateLabelColor"
              hide-canvas
              hide-inputs
              hide-sliders
              show-swatches
              @update:color="updateLabel('color', item)"
              @keydown.esc="updateLabelColor = null"
            />
          </v-card>
        </v-menu>
        <v-menu
          v-model="menus.title[item.id]"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-chip
              :color="item.color"
              :text-color="getTextContrastColor(item.color)"
              style="cursor: pointer"
              v-bind="attrs"
              @click="updateLabelTitle = item.title"
              v-on="on"
              >{{ item.title }}
            </v-chip>
          </template>

          <v-card class="pa-5">
            <v-text-field
              v-model="updateLabelTitle"
              autofocus
              hide-details
              label="Title"
              @keydown.enter="updateLabel('title', item)"
              @keydown.esc="updateLabelTitle = null"
            />
          </v-card>
        </v-menu>
        <v-spacer />
        <v-menu
          v-model="menus.description[item.id]"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              @click="updateLabelDescription = item.description"
              v-on="on"
            >
              {{ item.description }}
            </div>
          </template>

          <v-card class="pa-5">
            <v-text-field
              v-model="updateLabelDescription"
              autofocus
              hide-details
              label="Description"
              @keydown.enter="updateLabel('description', item)"
              @keydown.esc="updateLabelDescription = null"
            />
          </v-card>
        </v-menu>
        <v-spacer />
        <v-divider class="mx-2" vertical />
        <v-icon class="ant-icon" dense @click="setupDeleteLabel(item)"
          >mdi-delete
        </v-icon>
        <delete-dialog
          :dialog="deleteLabelDialog"
          :title="`Are you sure you want to delete '${
            deleteLabelItem ? deleteLabelItem.title : ''
          }' from this license`"
          @closeDialog="
            deleteLabelItem = null;
            deleteLabelDialog = false;
          "
          @deleteAction="deleteLabel"
        />
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-center justify-center full-width full-height">
    <ant-loading />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ColorHelper from '@/services/color-helper';
import AntLoading from '@/components/AntLoading';
import DeleteDialog from '@/components/DeleteDialog';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'ProjectLabels',
  components: { AntInput, DeleteDialog, AntLoading },
  data: () => {
    return {
      addLabelTitle: null,
      addLabelToggle: false,
      updateLabelTitle: null,
      updateLabelDescription: null,
      updateLabelColor: null,
      deleteLabelDialog: false,
      deleteLabelItem: null,
      menus: {
        color: {},
        description: {},
        title: {},
      },
    };
  },
  computed: {
    ...mapGetters(['labels', 'labelsStatus', 'selectedLicense', 'project']),
  },
  mounted() {
    this.$store.dispatch('fetchLabels', {
      'filter[project]': this.project.id,
    });
  },
  methods: {
    setupDeleteLabel(label) {
      this.deleteLabelItem = label;
      this.deleteLabelDialog = true;
    },
    getTextContrastColor(color) {
      return ColorHelper.getBlackOrWhiteBasedOnBackground(color);
    },
    createLabel() {
      let label = {
        license: this.selectedLicense.id,
        project: this.project.id,
        title: this.addLabelTitle,
        description: 'Placeholder',
        color: '#DCDCDC',
      };
      this.$store.dispatch('createLabel', label).then(() => {
        this.addLabelToggle = false;
        this.addLabelTitle = null;
      });
    },
    updateLabel(field, label) {
      let body = {};
      switch (field) {
        case 'description':
          body.description = this.updateLabelDescription;
          break;
        case 'title':
          body.title = this.updateLabelTitle;
          break;
        case 'color':
          body.color = this.updateLabelColor.hex;
          break;
      }
      this.$store
        .dispatch('updateLabel', { labelId: label.id, body })
        .then(() => {
          switch (field) {
            case 'description':
              this.menus.description[label.id] = false;
              break;
            case 'title':
              this.menus.title[label.id] = false;
              break;
            case 'color':
              this.menus.color[label.id] = false;
              break;
          }
        });
    },
    deleteLabel() {
      this.$store
        .dispatch('deleteLabel', { labelId: this.deleteLabelItem.id })
        .then(() => {
          this.deleteLabelItem = null;
          this.deleteLabelDialog = false;
        });
    },
  },
};
</script>

<style>
.admin-label-item {
  height: 50px;
}
</style>
